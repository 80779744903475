<div class="grid-container">
  <mat-grid-list cols="6" rowHeight="187.5px">
    <mat-grid-tile *ngFor="let channel of twitchAggregation;
                           let i = index;
                           trackBy: trackVideoCards"
                   [colspan]="cardSize(i).cols"
                   [rowspan]="cardSize(i).rows">
      <div class="video-content">
        <app-twitch-user-card [details]="channel"
                              [feedbackLoop]="channelFeedbackLoop"
                              (channelInteraction)="receiveChannelInteraction($event)"></app-twitch-user-card>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
